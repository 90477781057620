import {
  HTTP_STATUS, REQUEST_TYPES, ROLES, CALCULATION_METHOD
} from '../helpers/constants';

export function isAlphanumeric(inputtxt) {
  const letters = /^[ 0-9a-zA-Z]*$/;
  if (inputtxt.match(letters)) {
    return true;
  }
  return false;
}

export function isNumeric(value) {
  return !!value.match(/^\d*$/);
}

export function isSquareBracketsIncluded(inputtxt) {
  if (inputtxt.includes('[') || inputtxt.includes(']')) {
    return true;
  }
  return false;
}

export function isTotalFileSizeValid(limit, files) {
  let totalSize = 0;
  files.forEach((file) => {
    totalSize += file.size;
  });
  return totalSize < limit * 1024 * 1024;
}

export function isFileCountValid(files, isMultiFile) {
  return !isMultiFile && files.length > 1;
}

export function isArrayContentEqual(first, second) {
  if (first.length !== second.length) {
    return false;
  }
  return first.every((element) => {
    if (!second.includes(element)) {
      return false;
    }
    return true;
  });
}

export function isEmptyObject(obj) {
  return (!obj || Object.keys(obj).length === 0);
}

export function isEmptyArray(arr) {
  return (!arr || arr.length === 0);
}

export function responseStatusValidator(status, isCookie) {
  if (status === HTTP_STATUS.UNAUTHORIZED && isCookie) {
    window.location.assign('/login');
  } else if (status === HTTP_STATUS.TEMPORARY_REDIRECT) {
    window.location.assign('/maintenanceView.html');
  }
  return status >= 200 && status < 300;
}

export function isCostMaintenanceRequest(requestType) {
  if (requestType && requestType.code) {
    const { code } = requestType;
    if (code === REQUEST_TYPES.VENDOR_COST_ADHOC
      || code === REQUEST_TYPES.SYGMA_COST_CHANGE
      || code === REQUEST_TYPES.BIS_COST_CHANGE
      || code === REQUEST_TYPES.COMMODITY_COST_CHANGE
      || code === REQUEST_TYPES.MARKET_GROUP_PRICING
    ) {
      return true;
    }
  }
  return false;
}

export function isEnterpriseCostReadOnlyUser(roles) {
  return roles && roles.some((role) => (role === ROLES.ENTERPRISE_COST_READ_ONLY_ASSOCIATE));
}

export function validateRequestType(requestTypeObj, expectedRequestType) {
  if (requestTypeObj && requestTypeObj.code) {
    const actualRequestType = requestTypeObj.code;
    return (actualRequestType === expectedRequestType);
  }
  return false;
}

export function isMarketPriceEditable(calculationMethod) {
  return (calculationMethod === CALCULATION_METHOD.SPC)
    || (calculationMethod === CALCULATION_METHOD.SQUEEZE)
    || (calculationMethod === CALCULATION_METHOD.SELL_MINUS);
}

export function isHomeLocation() {
  const nextLocation = document.location?.pathname;
  return /^\/$/.test(nextLocation);
}

export function isRequestDetailsLocation() {
  const nextLocation = document.location.pathname;
  return /^\/requests/.test(nextLocation);
}

export function validateSasNumberFormat(value) {
  return /(^\d{0,4})[^.]*((?:\.\d{0,4})?)/g.exec(value);
}

export function validateFdNumberFormat(value) {
  return /(^-?\d{0,4})[^.]*((?:\.\d{0,4})?)/g.exec(value);
}
