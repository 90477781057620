import initialState from './initialState';
import {
  FETCH_AGREEMENT_DATA,
  RECEIVE_AGREEMENT_DATA,
  RESET_AGREEMENT_DATA,
  RECEIVE_ERROR_IN_AGREEMENT_FETCH,
  RESET_ERROR_MESSAGE,
} from '../actions/agreementActions';
/**
 * Agreement Data Reducer Class
 * @author skit1169
 */
export default function agreementDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.agreementData;
  }

  if (type === FETCH_AGREEMENT_DATA) {
    return { ...state, loading: true, error: { show: false, message: '' } };
  }

  if (type === RESET_ERROR_MESSAGE) {
    return { ...state, error: { show: false, message: '' } };
  }

  if (type === RECEIVE_AGREEMENT_DATA) {
    return { ...state, ...data, loading: false };
  }

  if (type === RECEIVE_ERROR_IN_AGREEMENT_FETCH) {
    return { ...state, loading: false, error: { show: true, message: data } };
  }

  if (type === RESET_AGREEMENT_DATA) {
    return initialState.agreementData;
  }

  return state;
}
