import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import navigation from '../../_nav';
import { isEnterpriseCostReadOnlyUser } from '../../util/validator';
// eslint-disable-next-line import/no-cycle
import routes from '../../routes';
import Footer from './Footer';
import HeaderView from './Header';
import { authorize } from '../../service/userService';
import getAuthCookie from '../../service/getAuthCookie';

const navigations = navigation;

export class BaseLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllCookieEnabled: true,
      showBanner: true
    };
    this.authorizedRoutes = [];
    routes.forEach((route) => {
      if (route.permissions) {
        if (authorize(route.permissions)) {
          this.authorizedRoutes.push(route);
        }
      } else {
        this.authorizedRoutes.push(route);
      }
    });

    this.authorizedNavigations = { items: [] };
    navigations.items.forEach((nav) => {
      if (nav.permissions) {
        if (authorize(nav.permissions) === true) {
          this.authorizedNavigations.items.push(nav);
        }
      } else {
        this.authorizedNavigations.items.push(nav);
      }
    });
  }

  async componentDidUpdate() {
    try {
      const isAllCookieEnabled = await getAuthCookie();
      if (!isAllCookieEnabled) {
        this.setState({ isAllCookieEnabled });
      }
    } catch (e) {
      console.error('Error when retrieving cookie', e);
    }
  }

  dismissBanner() {
    this.setState({
      showBanner: false
    });
  }

  render() {
    if (!this.state.isAllCookieEnabled) {
      return <Redirect to="/login" />;
    }
    const { roles } = this.props.user;
    const isEnterpriseCostUser = isEnterpriseCostReadOnlyUser(roles);
    return (
      <div className="app">
        <AppHeader fixed>
          <HeaderView
            user={this.props.user}
            isEnterpriseCostUser={isEnterpriseCostUser}
          />
        </AppHeader>
        <div className="app-body">
          {!isEnterpriseCostUser && (
            <AppSidebar fixed display="lg">
              <AppSidebarNav
                navConfig={this.authorizedNavigations}
                {...this.props}
              />
              <AppSidebarMinimizer />
            </AppSidebar>
          )}
          <main className="main">
            {isEnterpriseCostUser ? (
              <AppBreadcrumb appRoutes={[routes[0]]} />
            ) : (
              <AppBreadcrumb appRoutes={routes} />
            )}
            <Container fluid style={{ padding: '0rem 1rem' }}>
              <Switch>
                {this.props.user.disableDashboardRedirection && (
                  <Redirect to="/welcome" />
                )}
                {this.authorizedRoutes
                  && this.authorizedRoutes.map((route) => (route.component ? (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                  ) : null))}
                <Redirect from="/" to="/" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter fixed style={{ height: '30px' }}>
          <Footer />
        </AppFooter>
      </div>
    );
  }
}

BaseLayout.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.userReducer,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
