/**
 * Formula Group Saga
 *
 * @author agal0301
 * */

import {
  all, call, put, takeEvery,
  takeLatest
} from 'redux-saga/effects';
import api from '../../externals/formulaGroupProxy';
import {
  REQUEST_MARKET_GROUPS,
  REQUEST_SELECTED_MARKET_GROUP,
  SUBMIT_NEW_MARKET_RATE,
  SUBMIT_MODIFIED_MARKET_GROUP,
  DELETE_MARKET_GROUP,
  CREATE_MARKET_GROUP,
  REQUEST_MARKET_GROUP_ITEMS,
  DELETE_MARKET_GROUP_ITEMS,
  ADD_MARKET_GROUP_ITEMS,
  IMPORT_MASS_ITEM_ASSOCIATION_FILE,
  ASSIGN_MASS_ITEM_DATA,
  REVALIDATE_MASS_ITEM_DATA,
  updateSelectedMarketGroupData,
  updateSelectedMarketGroup,
  updateActiveMarketGroups,
  openLoaderForMarketGroupOperation,
  receiveSuccessResponseInMarketGroupOperation,
  updateMarketGroupItems,
  resetMarketGroupData,
  updateMassItemAssociationData,
  updateAddItems,
} from '../actions/formulaGroupActions';
import {
  receiveErrorInFormulaGroupRequest
} from '../actions/errorDialogActions';
import {
  UNABLE_TO_FETCH_ACTIVE_FORMULA_GROUPS,
  UNABLE_TO_FETCH_FORMULA_GROUP_DATA,
  UNABLE_TO_DELETE_THE_MARKET_GROUP,
  UNABLE_TO_DELETE_FORMULA_GROUP_ITEMS,
  UNABLE_TO_FETCH_FORMULA_GROUP_ITEMS,
  UNABLE_TO_UPLOAD_MASS_ITEM_DATA,
  UNABLE_TO_REVALIDATE_MASS_ITEM_DATA
} from '../../helpers/messageConstant';

export function* fetchAllActiveMarketGroups() {
  try {
    const marketGroups = yield call(api.fetchAllMarketGroups);
    yield put(updateActiveMarketGroups(marketGroups));
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(UNABLE_TO_FETCH_ACTIVE_FORMULA_GROUPS));
  }
}

export function* fetchSelectedMarketGroup({ data }) {
  try {
    const marketGroup = yield call(api.fetchSelectedMarketGroup, data);
    yield put(updateSelectedMarketGroupData(marketGroup));
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(e.message));
  }
}

export function* submitMarketRate({ data }) {
  let businessProcessId;
  try {
    const response = yield call(api.submitMarketRate, data.id, data.marketRate);
    // result of the first saga effect
    businessProcessId = response.businessProcessId;
    const marketGroup = yield call(api.fetchSelectedMarketGroup, data.id);
    yield all([put(updateSelectedMarketGroupData(marketGroup)), put(receiveSuccessResponseInMarketGroupOperation(`Request #${businessProcessId} has been received`))]);
  } catch (e) {
    if (e.message === UNABLE_TO_FETCH_FORMULA_GROUP_DATA) {
      yield all([put(resetMarketGroupData()), put(receiveSuccessResponseInMarketGroupOperation(`Request #${businessProcessId} has been received`))]);
    } else {
      yield put(receiveErrorInFormulaGroupRequest(e.message));
    }
  }
}

export function* submitModifiedMarketGroup({ data }) {
  const { currData, prevData, activeMarketGroups } = data;
  let updatedActiveMarketGroups = [];
  let updatedSelectedMarketGroup = null;
  try {
    const id = currData.code;
    // form the request body
    const requestBody = { ...currData };
    delete requestBody.code;
    delete requestBody.marketRate;
    yield all([call(api.submitModifiedMarketGroup, id, requestBody), put(openLoaderForMarketGroupOperation('Updating Market Group...'))]);
    updatedActiveMarketGroups = activeMarketGroups.map((group) => {
      if (group.code === id) {
        return { ...group, name: currData.name };
      }
      return group;
    });
    updatedSelectedMarketGroup = { value: id, label: `${id} - ${currData.name}`, name: currData.name };
    const marketGroup = yield call(api.fetchSelectedMarketGroup, id);
    yield all([put(updateActiveMarketGroups(updatedActiveMarketGroups)), put(updateSelectedMarketGroup(updatedSelectedMarketGroup)),
      put(updateSelectedMarketGroupData(marketGroup)), put(receiveSuccessResponseInMarketGroupOperation('Market Group Sucessfully Updated'))]);
  } catch (e) {
    if (e.message === UNABLE_TO_FETCH_FORMULA_GROUP_DATA) {
      yield all([put(updateActiveMarketGroups(updatedActiveMarketGroups)), put(updateSelectedMarketGroup(updatedSelectedMarketGroup)),
        put(resetMarketGroupData()), put(receiveSuccessResponseInMarketGroupOperation('Market Group Sucessfully Updated'))]);
    } else {
      yield all([put(updateSelectedMarketGroupData(prevData)), put(receiveErrorInFormulaGroupRequest(e.message))]);
    }
  }
}

export function* deleteMarketGroup({ data }) {
  const { id, marketGroups } = data;
  try {
    yield all([call(api.deleteMarketGroup, id), put(openLoaderForMarketGroupOperation('Deleting Market Group...'))]);
    const updatedActiveMarketGroups = marketGroups.filter((group) => group.code !== id);
    yield all([put(updateActiveMarketGroups(updatedActiveMarketGroups)), put(updateSelectedMarketGroupData({})), put(updateSelectedMarketGroup(null)), put(receiveSuccessResponseInMarketGroupOperation('Market Group Sucessfully Deleted'))]);
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(UNABLE_TO_DELETE_THE_MARKET_GROUP));
  }
}

export function* createMarketGroup({ data }) {
  try {
    const resultList = yield all([call(api.createMarketGroup, data.createdMarketGroup), put(openLoaderForMarketGroupOperation('Creating new Market Group...'))]);
    // result of the first saga effect
    const { code } = resultList[0];
    const updatedActiveMarketGroups = [...data.activeMarketGroups, { code, name: data.createdMarketGroup.name }];
    yield all([put(updateActiveMarketGroups(updatedActiveMarketGroups)), put(receiveSuccessResponseInMarketGroupOperation('Market Group Sucessfully Created'))]);
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(e.message));
  }
}

export function* fetchSelectedMarketGroupItems({ data }) {
  try {
    const marketGroupItems = yield call(api.fetchSelectedMarketGroupItems, data.value);
    yield all([put(updateMarketGroupItems(marketGroupItems)), put(updateSelectedMarketGroup(data))]);
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(e.message));
  }
}

export function* deleteMarketGroupItems({ data }) {
  try {
    yield all([call(api.deleteMarketGroupItems, data.id, data.deleteItems), put(openLoaderForMarketGroupOperation('Deleting Market Group Item(s)...'))]);
    yield all([put(updateMarketGroupItems(data.updatedAllItems)), put(receiveSuccessResponseInMarketGroupOperation('Item(s) Removed Sucessfully'))]);
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(UNABLE_TO_DELETE_FORMULA_GROUP_ITEMS));
  }
}

export function* addMarketGroupItems({ data }) {
  const requestBody = data.addItems.map((itemDetail) => ({ item: itemDetail.item, vendor: itemDetail.vendor, warehouse: itemDetail.warehouse }));
  try {
    const addItemsResponse = yield call(api.addMarketGroupItems, data.id, requestBody);
    if (addItemsResponse.error) {
      const { invalidRecords } = addItemsResponse;
      const updatedAddItems = data.addItems.map((addItem) => {
        const match = invalidRecords.find((invalidRecord) => invalidRecord.item === addItem.item
          && invalidRecord.vendor === addItem.vendor
          && invalidRecord.warehouse === addItem.warehouse);

        if (match) {
          return { ...addItem, errorMessage: match.errorMessage };
        }
        return addItem;
      });
      yield put(updateAddItems(updatedAddItems));
    } else {
      const marketGroupItems = yield call(api.fetchSelectedMarketGroupItems, data.id);
      yield all([put(updateMarketGroupItems(marketGroupItems)),
        put(updateAddItems([
          {
            item: '',
            vendor: '',
            warehouse: '',
            isSelected: false,
            isInvalid: {
              isInvalidItem: false,
              isInvalidVendor: false,
              isInvalidWarehouse: false
            },
            errorMessage: ''
          }
        ])),
        put(receiveSuccessResponseInMarketGroupOperation('Item(s) Added Sucessfully'))]);
    }
  } catch (e) {
    if (e.message === UNABLE_TO_FETCH_FORMULA_GROUP_ITEMS) {
      yield all([put(receiveSuccessResponseInMarketGroupOperation('Item(s) Added Sucessfully')), put(resetMarketGroupData())]);
    }
    yield put(receiveErrorInFormulaGroupRequest(e.message));
  }
}

export function* importMassItemAssociationFile({ data }) {
  try {
    const resultList = yield all([call(api.importMassItemAssociationFile, data), put(openLoaderForMarketGroupOperation('Importing item data from file... this might take a while'))]);
    // result of the first saga effect
    const massItemFileImportResponse = resultList[0];
    if (massItemFileImportResponse.error) {
      yield put(receiveErrorInFormulaGroupRequest(massItemFileImportResponse.errorMessage));
    } else {
      yield put(updateMassItemAssociationData(massItemFileImportResponse));
    }
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(e.message));
  }
}

export function* assignMassItemData({ data }) {
  try {
    const resultList = yield all([call(api.assignMassItemData, data), put(openLoaderForMarketGroupOperation('Uploading valid item data... this might take a while'))]);
    // result of the first saga effect
    const response = resultList[0];
    if (response.error) {
      yield put(receiveErrorInFormulaGroupRequest(response.errorMessage));
    } else {
      yield all([put(updateMassItemAssociationData({ validRecords: [], invalidRecords: [] })), put(receiveSuccessResponseInMarketGroupOperation('Item(s) Uploaded Sucessfully'))]);
    }
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(UNABLE_TO_UPLOAD_MASS_ITEM_DATA));
  }
}

export function* revalidateMassItemData({ data }) {
  try {
    const resultList = yield all([call(api.revalidateMassItemData, data), put(openLoaderForMarketGroupOperation('Re-validating item data... this might take a while'))]);
    // result of the first saga effect
    const massItemRevalidationResponse = resultList[0];
    if (massItemRevalidationResponse.error) {
      yield put(receiveErrorInFormulaGroupRequest(massItemRevalidationResponse.errorMessage));
    } else {
      yield put(updateMassItemAssociationData(massItemRevalidationResponse));
    }
  } catch (e) {
    yield put(receiveErrorInFormulaGroupRequest(UNABLE_TO_REVALIDATE_MASS_ITEM_DATA));
  }
}

export function* formulaGroupSaga() {
  yield takeLatest(REQUEST_MARKET_GROUPS, fetchAllActiveMarketGroups);
  yield takeLatest(REQUEST_SELECTED_MARKET_GROUP, fetchSelectedMarketGroup);
  yield takeEvery(SUBMIT_NEW_MARKET_RATE, submitMarketRate);
  yield takeEvery(SUBMIT_MODIFIED_MARKET_GROUP, submitModifiedMarketGroup);
  yield takeEvery(DELETE_MARKET_GROUP, deleteMarketGroup);
  yield takeEvery(CREATE_MARKET_GROUP, createMarketGroup);
  yield takeLatest(REQUEST_MARKET_GROUP_ITEMS, fetchSelectedMarketGroupItems);
  yield takeEvery(DELETE_MARKET_GROUP_ITEMS, deleteMarketGroupItems);
  yield takeEvery(ADD_MARKET_GROUP_ITEMS, addMarketGroupItems);
  yield takeEvery(IMPORT_MASS_ITEM_ASSOCIATION_FILE, importMassItemAssociationFile);
  yield takeEvery(ASSIGN_MASS_ITEM_DATA, assignMassItemData);
  yield takeEvery(REVALIDATE_MASS_ITEM_DATA, revalidateMassItemData);
}
