/**
 * @author skit1169
 * */

/**
 * Action Types
 */
export const RECEIVE_AGREEMENT_DATA = 'RECEIVE_AGREEMENT_DATA';
export const FETCH_AGREEMENT_DATA = 'FETCH_AGREEMENT_DATA';
export const RESET_AGREEMENT_DATA = 'RESET_AGREEMENT_DATA';
export const RECEIVE_ERROR_IN_AGREEMENT_FETCH = 'RECEIVE_ERROR_IN_AGREEMENT_FETCH';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

export const FETCH_AGREEMENTS_SEARCH_DATA = 'FETCH_AGREEMENTS_SEARCH_DATA';
export const RECEIVE_AGREEMENTS_SEARCH_DATA = 'RECEIVE_AGREEMENTS_SEARCH_DATA';
export const RESET_AGREEMENTS_SEARCH_DATA = 'RESET_AGREEMENTS_SEARCH_DATA';

export const FETCH_VENDOR_BASES = 'FETCH_VENDOR_BASES';
export const RECEIVE_VENDOR_BASES = 'RECEIVE_VENDOR_BASES';

export const FETCH_BUSINESS_CENTERS = 'FETCH_BUSINESS_CENTERS';
export const RECEIVE_BUSINESS_CENTERS = 'RECEIVE_BUSINESS_CENTERS';

export const FETCH_SAS_FD_DETAILS = 'FETCH_SAS_FD_DETAILS';
export const RECEIVE_SAS_FD_DETAILS = 'RECEIVE_SAS_FD_DETAILS';

export const SAVE_SAS_VALUE_FOR_VENDOR = 'SAVE_SAS_VALUE_FOR_VENDOR';
export const RECEIVE_SAS_VALUE_UPDATE_STATUS_FOR_VENDOR = 'RECEIVE_SAS_VALUE_UPDATE_STATUS_FOR_VENDOR';

export const REQUEST_SAS_FD_FILE_IMPORT = 'REQUEST_SAS_FD_FILE_IMPORT';
export const RECEIVE_SAS_FD_FILE_IMPORT = 'RECEIVE_SAS_FD_FILE_IMPORT';

export const ADD_SAS_VALUE_FOR_VENDOR = 'ADD_SAS_VALUE_FOR_VENDOR';
export const RECEIVE_SAS_VALUE_ADD_STATUS_FOR_VENDOR = 'RECEIVE_SAS_VALUE_ADD_STATUS_FOR_VENDOR';

export const SAVE_SAS_FD_DETAILS_FOR_ITEM = 'SAVE_SAS_FD_DETAILS_FOR_ITEM';
export const RECEIVE_SAS_FD_DETAILS_UPDATE_STATUS_FOR_ITEM = 'RECEIVE_SAS_FD_DETAILS_UPDATE_STATUS_FOR_ITEM';

/**
 * Actions
 */
export const fetchAgreementData = (data) => ({ type: FETCH_AGREEMENT_DATA, data });
export const receiveAgreementData = (data) => ({ type: RECEIVE_AGREEMENT_DATA, data });
export const resetAgreementData = () => ({ type: RESET_AGREEMENT_DATA });
export const receiveErrorInAgreementFetch = (data) => ({ type: RECEIVE_ERROR_IN_AGREEMENT_FETCH, data });
export const resetErrorMessage = () => ({ type: RESET_ERROR_MESSAGE });

export const fetchAgreementsSearchData = (data) => ({ type: FETCH_AGREEMENTS_SEARCH_DATA, data });
export const receiveAgreementsSearchData = (data) => ({ type: RECEIVE_AGREEMENTS_SEARCH_DATA, data });
export const resetAgreementsSearchData = () => ({ type: RESET_AGREEMENTS_SEARCH_DATA });

export const fetchVendorBases = () => ({ type: FETCH_VENDOR_BASES });
export const receiveVendorBases = (data) => ({ type: RECEIVE_VENDOR_BASES, data });

export const fetchBusinessCenters = (data) => ({ type: FETCH_BUSINESS_CENTERS, data });
export const receiveBusinessCenters = (data) => ({ type: RECEIVE_BUSINESS_CENTERS, data });

export const fetchSASFDDetails = (data) => ({ type: FETCH_SAS_FD_DETAILS, data });
export const receiveSASFDDetails = (data) => ({ type: RECEIVE_SAS_FD_DETAILS, data });

export const saveSASValueForVendor = (data) => ({ type: SAVE_SAS_VALUE_FOR_VENDOR, data });
export const receiveSASValueUpdateStatus = (data) => ({ type: RECEIVE_SAS_VALUE_UPDATE_STATUS_FOR_VENDOR, data });

export const requestSASFDFileImport = (data) => ({ type: REQUEST_SAS_FD_FILE_IMPORT, data });
export const receiveSASFDFileImport = (data) => ({ type: RECEIVE_SAS_FD_FILE_IMPORT, data });

export const addSASValueForVendor = (data) => ({ type: ADD_SAS_VALUE_FOR_VENDOR, data });
export const receiveSASValueAddStatus = (data) => ({ type: RECEIVE_SAS_VALUE_ADD_STATUS_FOR_VENDOR, data });

export const saveSasFdDetailsForItems = (data) => ({ type: SAVE_SAS_FD_DETAILS_FOR_ITEM, data });
export const receiveSasFdDetailsForItems = (data) => ({ type: RECEIVE_SAS_FD_DETAILS_UPDATE_STATUS_FOR_ITEM, data });
