/**
 * Formula Group Actions
 *
 * @author agal0301
 * */

export const REQUEST_MARKET_GROUPS = 'REQUEST_MARKET_GROUPS';
export const UPDATE_MARKET_GROUPS = 'UPDATE_MARKET_GROUPS';
export const REQUEST_SELECTED_MARKET_GROUP = 'REQUEST_SELECTED_MARKET_GROUP';
export const UPDATE_SELECTED_MARKET_GROUP_DATA = 'UPDATE_SELECTED_MARKET_GROUP_DATA';
export const CREATE_MARKET_GROUP = 'CREATE_MARKET_GROUP';
export const DELETE_MARKET_GROUP = 'DELETE_MARKET_GROUP';
export const SUBMIT_MODIFIED_MARKET_GROUP = 'SUBMIT_MODIFIED_MARKET_GROUP';
export const SUBMIT_NEW_MARKET_RATE = 'SUBMIT_NEW_MARKET_RATE';
export const REQUEST_MARKET_GROUP_ITEMS = 'REQUEST_MARKET_GROUP_ITEMS';
export const UPDATE_DELETE_ITEMS = 'UPDATE_DELETE_ITEMS';
export const UPDATE_ADD_ITEMS = 'UPDATE_ADD_ITEMS';
export const RESET_MARKET_GROUP_DATA = 'RESET_MARKET_GROUP_DATA';
export const DELETE_MARKET_GROUP_ITEMS = 'DELETE_MARKET_GROUP_ITEMS';
export const ADD_MARKET_GROUP_ITEMS = 'ADD_MARKET_GROUP_ITEMS';
export const RECEIVE_SUCCESS_IN_MARKET_GROUP_OPERATION = 'RECEIVE_SUCCESS_IN_MARKET_GROUP_OPERATION';
export const UPDATE_MARKET_GROUP_ITEMS = 'UPDATE_MARKET_GROUP_ITEMS';
export const OPEN_LOADER_FOR_MARKET_GROUP_OPERATION = 'OPEN_LOADER_FOR_MARKET_GROUP_OPERATION';
export const UPDATE_SELECTED_MARKET_GROUP = 'UPDATE_SELECTED_MARKET_GROUP';
export const IMPORT_MASS_ITEM_ASSOCIATION_FILE = 'IMPORT_MASS_ITEM_ASSOCIATION_FILE';
export const UPDATE_MASS_ITEM_ASSOCIATION_DATA = 'UPDATE_MASS_ITEM_ASSOCIATION_DATA';
export const ASSIGN_MASS_ITEM_DATA = 'ASSIGN_MASS_ITEM_DATA';
export const REVALIDATE_MASS_ITEM_DATA = 'REVALIDATE_MASS_ITEM_DATA';

export const requestActiveMarketGroups = () => ({
  type: REQUEST_MARKET_GROUPS
});

export const updateActiveMarketGroups = (data) => ({
  type: UPDATE_MARKET_GROUPS,
  data,
});

export const updateSelectedMarketGroupData = (data) => ({
  type: UPDATE_SELECTED_MARKET_GROUP_DATA,
  data,
});

export const requestSelectedMarketGroupData = (data) => ({
  type: REQUEST_SELECTED_MARKET_GROUP,
  data
});

export const submitNewRate = (data) => ({
  type: SUBMIT_NEW_MARKET_RATE,
  data
});

export const submitModifiedMarketGroup = (data) => ({
  type: SUBMIT_MODIFIED_MARKET_GROUP,
  data
});

export const deleteMarketGroup = (data) => ({
  type: DELETE_MARKET_GROUP,
  data
});

export const createMarketGroup = (data) => ({
  type: CREATE_MARKET_GROUP,
  data
});

export const requestMarketGroupItems = (data) => ({
  type: REQUEST_MARKET_GROUP_ITEMS,
  data
});

export const updateDeleteItems = (data) => ({
  type: UPDATE_DELETE_ITEMS,
  data
});

export const updateAddItems = (data) => ({
  type: UPDATE_ADD_ITEMS,
  data
});

export const resetMarketGroupData = (data) => ({
  type: RESET_MARKET_GROUP_DATA,
  data
});

export const deleteMarketGroupItems = (data) => ({
  type: DELETE_MARKET_GROUP_ITEMS,
  data
});

export const addMarketGroupItems = (data) => ({
  type: ADD_MARKET_GROUP_ITEMS,
  data
});

export const receiveSuccessResponseInMarketGroupOperation = (data) => ({
  type: RECEIVE_SUCCESS_IN_MARKET_GROUP_OPERATION,
  data
});

export const updateMarketGroupItems = (data) => ({
  type: UPDATE_MARKET_GROUP_ITEMS,
  data
});

export const openLoaderForMarketGroupOperation = (data) => ({
  type: OPEN_LOADER_FOR_MARKET_GROUP_OPERATION,
  data
});

export const updateSelectedMarketGroup = (data) => ({
  type: UPDATE_SELECTED_MARKET_GROUP,
  data
});

export const importMassItemAssociationFile = (data) => ({
  type: IMPORT_MASS_ITEM_ASSOCIATION_FILE,
  data
});

export const updateMassItemAssociationData = (data) => ({
  type: UPDATE_MASS_ITEM_ASSOCIATION_DATA,
  data
});

export const assignMassItemData = (data) => ({
  type: ASSIGN_MASS_ITEM_DATA,
  data
});

export const revalidateMassItemData = (data) => ({
  type: REVALIDATE_MASS_ITEM_DATA,
  data
});
