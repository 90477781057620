/**
 * Formula Group Reducer
 *
 * @author agal0301
 * */
import initialState from './initialState';
import {
  UPDATE_MARKET_GROUPS,
  UPDATE_SELECTED_MARKET_GROUP_DATA,
  UPDATE_DELETE_ITEMS,
  UPDATE_ADD_ITEMS,
  RESET_MARKET_GROUP_DATA,
  UPDATE_MARKET_GROUP_ITEMS,
  UPDATE_SELECTED_MARKET_GROUP,
  UPDATE_MASS_ITEM_ASSOCIATION_DATA,
  ADD_MARKET_GROUP_ITEMS,
} from '../actions/formulaGroupActions';
import { RECEIVE_ERROR_IN_FORMULA_GROUP_REQUEST } from '../actions/errorDialogActions';

export default function formulaGroupReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.formulaGroup;
  }

  if (type === RESET_MARKET_GROUP_DATA) {
    return {
      ...state,
      selectedMarketGroupData: {},
      selectedMarketGroup: null,
      items: {
        allItems: [],
        deleteItems: {},
        addItems: [
          {
            item: '',
            vendor: '',
            warehouse: '',
            isSelected: false,
            isInvalid: {
              isInvalidItem: false,
              isInvalidVendor: false,
              isInvalidWarehouse: false
            },
            errorMessage: ''
          }
        ],
        loading: false
      },
      massItems: {
        allItems: {
          validRecords: [],
          invalidRecords: []
        },
      }
    };
  }

  if (type === UPDATE_SELECTED_MARKET_GROUP) {
    return { ...state, selectedMarketGroup: data };
  }

  if (type === UPDATE_MARKET_GROUPS) {
    return { ...state, activeMarketGroups: data };
  }

  if (type === UPDATE_SELECTED_MARKET_GROUP_DATA) {
    return { ...state, selectedMarketGroupData: data };
  }

  if (type === UPDATE_MARKET_GROUP_ITEMS) {
    return {
      ...state,
      items: {
        allItems: data,
        deleteItems: {},
        addItems: [
          {
            item: '',
            vendor: '',
            warehouse: '',
            isSelected: false,
            isInvalid: {
              isInvalidItem: false,
              isInvalidVendor: false,
              isInvalidWarehouse: false
            },
            errorMessage: ''
          }
        ],
        loading: false
      }
    };
  }

  if (type === UPDATE_DELETE_ITEMS) {
    return { ...state, items: { ...state.items, deleteItems: data } };
  }

  if (type === UPDATE_ADD_ITEMS) {
    return { ...state, items: { ...state.items, addItems: data, loading: false } };
  }

  if (type === ADD_MARKET_GROUP_ITEMS) {
    return { ...state, items: { ...state.items, loading: true } };
  }

  if (type === RECEIVE_ERROR_IN_FORMULA_GROUP_REQUEST) {
    return { ...state, items: { ...state.items, loading: false } };
  }

  if (type === UPDATE_MASS_ITEM_ASSOCIATION_DATA) {
    return { ...state, massItems: { allItems: data } };
  }

  return state;
}
