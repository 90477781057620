import axios from 'axios';
import BFFProxy from './bffProxy';
import axiosWithPayloadInterceptor from '../interceptor/axiosWithPayloadInterceptor';
import {
  HTTP_METHODS,
  HTTP_STATUS,
  URLS,
  FILE_TYPES
} from '../helpers/constants';
import {
  UNABLE_TO_MODIFY_THE_MARKET_GROUP,
  UNABLE_TO_CREATE_THE_MARKET_GROUP,
  UNABLE_TO_FETCH_FORMULA_GROUP_DATA,
  UNABLE_TO_FETCH_FORMULA_GROUP_ITEMS,
  UNABLE_TO_ADD_FORMULA_GROUP_ITEMS,
  S3_FILE_UPLOAD_ERROR,
  FILE_IMPORT_ERROR,
  UNABLE_TO_UPDATE_THE_MARKET_RATE
} from '../helpers/messageConstant';

/**
 * Fetch data from Workspace API
 *
 * @author agal0301
 */
class FormulaGroupProxy {
  static async fetchAllMarketGroups() {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };
    const response = await axios(config);
    return response.data;
  }

  static async fetchSelectedMarketGroup(id) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/${id}`;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (e) {
      return Promise.reject(new Error(UNABLE_TO_FETCH_FORMULA_GROUP_DATA));
    }
  }

  static async submitMarketRate(id, data) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/rate/${id}`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === HTTP_STATUS.BAD_REQUEST) {
        return Promise.reject(new Error(e.response.data));
      }
      return Promise.reject(new Error(UNABLE_TO_UPDATE_THE_MARKET_RATE));
    }
  }

  static async submitModifiedMarketGroup(id, data) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/${id}`;
    const config = {
      url,
      method: HTTP_METHODS.PUT,
      data
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === HTTP_STATUS.BAD_REQUEST) {
        return Promise.reject(new Error(e.response.data));
      }
      return Promise.reject(new Error(UNABLE_TO_MODIFY_THE_MARKET_GROUP));
    }
  }

  static async deleteMarketGroup(id) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/${id}`;
    const config = {
      url,
      method: HTTP_METHODS.DELETE,
    };
    const response = await axios(config);
    return response.data;
  }

  static async createMarketGroup(data) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === HTTP_STATUS.BAD_REQUEST) {
        return Promise.reject(new Error(e.response.data));
      }
      return Promise.reject(new Error(UNABLE_TO_CREATE_THE_MARKET_GROUP));
    }
  }

  static async fetchSelectedMarketGroupItems(id) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/items/${id}`;
    const config = {
      url,
      method: HTTP_METHODS.GET
    };
    try {
      const response = await axiosWithPayloadInterceptor(config);
      return response.data;
    } catch {
      return Promise.reject(new Error(UNABLE_TO_FETCH_FORMULA_GROUP_ITEMS));
    }
  }

  static async deleteMarketGroupItems(id, data) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/items/${id}/delete`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }

  static async addMarketGroupItems(id, data) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/items/${id}/add`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === HTTP_STATUS.BAD_REQUEST) {
        return e.response.data;
      }
      return Promise.reject(new Error(UNABLE_TO_ADD_FORMULA_GROUP_ITEMS));
    }
  }

  static async importMassItemAssociationFile(file) {
    try {
      const signedUrlResponse = await BFFProxy.getSignedUrlToUpload(FILE_TYPES.MARKET_GROUP_MASS_ITEM_ASSOCIATION_FILE, file.name);
      const s3UploadResponse = await BFFProxy.uploadWithSignedUrl(signedUrlResponse.data.url, file, file.type);
      if (s3UploadResponse.status === HTTP_STATUS.OK) {
        const config = {
          url: `${URLS.FORMULA_GROUP_BASE_URL}/import/mass-item-file`,
          params: {
            filename: encodeURIComponent(signedUrlResponse.data.fileName),
          },
          method: HTTP_METHODS.GET
        };
        const response = await axiosWithPayloadInterceptor(config);
        return response.data;
      }
      return Promise.reject(new Error(S3_FILE_UPLOAD_ERROR));
    } catch (e) {
      if (e.response && e.response.status === HTTP_STATUS.BAD_REQUEST) {
        return Promise.reject(new Error(e.response.data));
      }
      return Promise.reject(new Error(FILE_IMPORT_ERROR));
    }
  }

  static async assignMassItemData(data) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/items`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }

  static async revalidateMassItemData(data) {
    const url = `${URLS.FORMULA_GROUP_BASE_URL}/items/validate`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axiosWithPayloadInterceptor(config);
    return response.data;
  }
}

export default FormulaGroupProxy;
