import initialState from './initialState';
import {
  RECEIVE_AGREEMENTS_SEARCH_DATA,
  RESET_AGREEMENTS_SEARCH_DATA,
} from '../actions/agreementActions';
/**
 * Agreement Search Data Reducer Class
 * @author fris4874
 */
export default function agreementSearchDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.agreementSearchData;
  }

  if (type === RECEIVE_AGREEMENTS_SEARCH_DATA) {
    return data;
  }

  if (type === RESET_AGREEMENTS_SEARCH_DATA) {
    return initialState.agreementSearchData;
  }

  return state;
}
