import { all } from 'redux-saga/effects';
import { filesSaga } from './filesSaga';
import { reportsSaga } from './reportsSaga';
import { fetchFileTransferDetailsSaga } from './filesTransferDetailsSaga';
import { userManagementSaga } from './userManagementSaga';
import { vendorSaga } from './vendorSaga';
import { workspaceSaga } from './workspaceSaga';
import { costMaintenanceSaga } from './costMaintenanceSaga';
import { enterpriseCostSaga } from './enterpriseCostSaga';
import { agreementSaga } from './agreementSaga';
import { utilitiesSaga } from './utilitiesSaga';
import { formulaGroupSaga } from './formulaGroupSaga';

function* rootSaga() {
  yield all([
    filesSaga(),
    fetchFileTransferDetailsSaga(),
    reportsSaga(),
    userManagementSaga(),
    vendorSaga(),
    workspaceSaga(),
    enterpriseCostSaga(),
    agreementSaga(),
    costMaintenanceSaga(),
    utilitiesSaga(),
    formulaGroupSaga()
  ]);
}

export default rootSaga;
